.mainView{
    /* background-color: grey; */
    height: 2500px;
    width: 2500px;
    display: flex;
    flex-direction: row;
    /* background-color: aqua; */
    
}

.shop{
    font-size: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #C5C5C5;
    border-radius: 0.6rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.shopDet{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.green{
    background-color: rgb(220, 255, 168);
}

.shop:hover{
    background-color: rgb(242, 242, 242);

    
}

.topItem{
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
}

.top{
    display: flex;
    flex-direction: row;
    margin-left: 5rem;
}

.shop img{
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.dltIcon {
    position: absolute;
    background: none;
    border: none;
    right: 0;
    transform: translate(5rem,0);
}

.dltIcon img{
    /* display: none; */
    height: 2rem;
    border-radius: 60rem;
    padding: 1rem;
    
}

.dltIcon img:hover{
    background-color: rgb(255, 214, 214);
}

.shop .dltIcon img{
    display: none;
}

.shop:hover .dltIcon img{
    display: inline;
}

