/* global css */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@300;400;500;600&display=swap');

*{
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

html{
    font-size: 8px;
}

.mainContainer{
    display: flex;
    flex-direction: column;
}

main{
    display: flex;
    flex-direction: row;
}

.bangla{
    font-family: 'Noto Sans Bengali', sans-serif;
    font-weight: 600;
}



nav{
    display: flex;
    justify-content: center;
    align-items: center;
}

ul{
    padding: 0 45px 0 45px;
    display: flex;
    list-style-type: none;
    flex-direction: column;
}

ul ul{
    opacity: 0;
    display: none;
    padding: 0;
    list-style-type: none;
    flex-direction: column;
    background-color: #f8f8f8;
    border-radius: 1.5rem;
}

li{
    height: 5.8rem;
    width: 21.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.4rem;
    margin: 0.6rem;
    align-items: center;
    cursor: pointer;
}

li:hover{
    border: 1px solid #0088C2; 
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}

ul ul li{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0rem;
    margin: 0rem;
    align-items: center;
    font-size: 1.7rem;
    border-radius: 1.4rem;
    color: #9A9A9A;
    
}

ul ul p{
    padding: 1.5rem;
    font-size: 1.7rem;
    border-bottom: 1px solid rgb(215, 215, 215);
    width: 15rem;
    color: #868686;
    font-weight: 500;    
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

ul ul li a{
    text-decoration: none;
    color: #9A9A9A;
    padding: 1.5rem;
    width: 20rem;

}

ul ul li:hover{
    background-color: #f2f2f2;
    border: none;
}

form div div{
    display: flex;
    flex-direction: column;
}

form{
    margin-left: 4.8rem;
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
}

label{
    padding-right: 4rem;
    padding-bottom: 0.5rem;
    font-weight: 400;
    color: #9A9A9A;

}

select, input{
    border: 1px solid #E9E9E9;
    border-radius: 1.3rem;
    height: 4.5rem;
    width: 25rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 1rem;
    appearance: none;
    outline-color: #0088C2;
    background-color: white;
}


input::placeholder{
    color: #9A9A9A;
}

textarea{
    border: 1px solid #E9E9E9;
    height: 29rem;
    width: 67.4rem;
    border-radius: 1.3rem;
    padding: 1rem 2rem 1rem 2rem;
    outline-color: #A70000;
}

textarea::placeholder{
    color: #9A9A9A;
}

select{
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

th{
    background-color: #e8e8e8;
    font-weight: 600;
    padding: 2rem 3rem 2rem 3rem; 
}

tr{
    background-color: #F8F8F8;
    border-radius: 0.7rem;
    cursor: pointer;
}

tr:hover{
    background-color: #efefef;
}

td{
    border-style: none solid solid none;
    padding: 2rem 3rem 2rem 3rem; 
}

tr th:first-child { border-top-left-radius: 16px; }
tr th:last-child { border-top-right-radius: 16px; }

tr th:first-child { border-bottom-left-radius: 16px; }
tr th:last-child { border-bottom-right-radius: 16px; }

tr th { border-top-style: solid; }
tr th:first-child { border-left-style: solid; }

tr td:first-child { border-top-left-radius: 16px; }
tr td:last-child { border-top-right-radius: 16px; }

tr td:first-child { border-bottom-left-radius: 16px; }
tr td:last-child { border-bottom-right-radius: 16px; }

tr td { border-top-style: solid; }
tr td:first-child { border-left-style: solid; }

table,th,td{
    border-collapse: separate;
    border: white;
    row-gap: 20rem;
    text-align: left;
}


td img{
    padding-right: 0.6rem;
}


input[type='date']::before {
    content: attr(placeholder);
    color: #9A9A9A;
}

.hide{
    display: none;
  }

  .reddd{
    color: #990000 !important;
    border: 1px solid #990000 !important;
    background-color: #FBF0F0 !important;
  }

@media screen and (max-width: 767px) {
    nav{
        padding-top: 41rem;
        justify-content: center;
    }

    li{
        width: 38rem;
    }

    ul ul li a{
        width: auto;
    }

    ul ul p{
        width: auto;
    }

    li:hover{
        border: none;
    }

    form{
        margin-left: 4.5rem;
        padding-top: 2rem;
        font-size: 1.7rem;
    }

    select{
        width: 83vw;
        height: 5.5rem;
        font-size: 1.7rem;
    }

    input{
        width: 74vw;
        height: 5.5rem;
        font-size: 1.7rem;
    }

    input::placeholder{
        font-size: 1.7rem;
    }

    textarea{
        width: 70vw;
    }

    th{
        padding: 2rem 1rem 2rem 1rem; 
    }

    td{
        padding: 2rem 1rem 2rem 1rem; 
    }
}


@media screen and (min-width: 768px) and (max-width:1023px) {
    ul{
        padding: 0 20px 0 20px;
    }

    form{
        margin-left: 6rem;
    }

    textarea{
        width: 50vw;
    }

    .formDetail{
        flex-direction: column;
    }

    th{
        padding: 2rem 2rem 2rem 2rem; 
    }

    td{

        padding: 2rem 1rem 2rem 1rem; 
    }
}

.mainSolute{
  position: absolute;
}

.mainSolute form{
  display: none;
}

.btn_del{
    width: 14.9rem;
    background-color: #C20000;
    color: white;
    border: none;
    height: 4.7rem;
    border-radius: 0.7rem;
}

.btn_edit{
    width: 14.9rem;
    background-color: #edf9ff;
    color: #0088C2;
    border: 1px solid #0088C2;
    height: 4.7rem;
    border-radius: 0.7rem;
    margin-right: 2rem;
}

.btn_updatePassword{
    width: 14.9rem;
    background-color: #edf9ff;
    color: #0088C2;
    border: 1px solid #0088C2;
    height: 4.7rem;
    border-radius: 0.7rem;
    margin-right: 2rem;
}


.btn_edit:hover{
    width: 14.9rem;
    background-color: #cdeeff;
    color: #0088C2;
    border: 1px solid #0088C2;
    height: 4.7rem;
    border-radius: 0.7rem;
}

.btn_downloadFile{
    background-color: #edf9ff;
    color: #0088C2;
    border: 1px solid #0088C2;
    height: 4.7rem;
    border-radius: 0.7rem;
    margin-right: 2rem;
}


.btn_downloadFile:hover{
    background-color: #cdeeff;
}

.detailButtons button{
    margin-bottom: 1rem;
}

.onlyPrint{
  display: none;
}

.deletePrompt{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  /* opacity: 0.3; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.deletePrompt label{
  text-align: center;
  margin-top: 7rem;
  font-size: 20px;
  padding: 0;
  color: black;
  font-weight: 400;
}

.deletePrompt div{
  width: 50rem;
  height: 25rem;
  background-color: white;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.deletePrompt button{
  width: 15rem;
  border: none;
  border-radius: 0.8rem;
  color: white;
  height: 5rem;
  margin: 1rem;
}

.deletePrompt div div{
  flex-direction: row;
}

@media print
{

  .onlyPrint{
    display: inline;
  }

  form{
    margin: 0;
  }

  .whitespace{
    opacity: 0;
  }

    .posPrint{
      font-size: 16px;
    }  

    .dontPrintButton{
      display: none;
    }
  
    .no-print, .no-print *
    {
        display: none !important;
    }

    tr td{
        outline: 1px solid black;
    }
    tr th:first-child { border-top-left-radius: 0px; }
    tr th:last-child { border-top-right-radius: 0px; }

    tr th:first-child { border-bottom-left-radius: 0px; }
    tr th:last-child { border-bottom-right-radius: 0px; }

    tr th { border-top-style: solid; }
    tr th:first-child { border-left-style: solid; }

    tr td:first-child { border-top-left-radius: 0px; }
    tr td:last-child { border-top-right-radius: 0px; }

    tr td:first-child { border-bottom-left-radius: 0px; }
    tr td:last-child { border-bottom-right-radius: 0px; }

    tr td { border-top-style: solid; }
    tr td:first-child { border-left-style: solid; }


    td img{
        display: none;
    }

    td{
      padding: 0.2rem;
      text-align: center;
    }

    .ld{
      display: inline;
    }
    
}



#completion {
    width:20%;
    height:20%;
    display: block;
  }
  
  @keyframes hideshow {
    0% { opacity: 0.2; }
    10% { opacity: 0.2; }
    15% { opacity: 0.2; }
    100% { opacity: 1; }
  } 
  
  #cirkel { 
    animation: hideshow 0.4s ease;
  }
  
  #check {
    animation: hideshow 0.4s ease;
  }
  
  #stars {
    animation: hideshow 1.0s ease;
    opacity: 0.9;   
  }
  
  
  @keyframes hideshow {
    0% {
      transform: scale(0.2);
      transform-origin: initial;
      
    }
    100% {
      transform: scale(1.0);
      transform-origin: initial;
    }
  }
  
  @keyframes draaien {
    0% {
      transform: rotate(40deg);
      transform-origin: initial;
      
    }
    100% {
      transform: scale(0deg);
      transform-origin: initial;
    }
  }
  
  #check {
    animation: draaien 0.8s ease;
  }
  
  
  @keyframes transparant {
    0% {
      opacity: 0;
      
    }
    100% {
      opacity: 1;
    }
  }
  
  #check {
    animation: transparant 2s ;
  }
  
  .toggle {
    position: relative;
    height: 12px;
    width: 125px;
    cursor: pointer;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
  .toggle::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #ffcc00;
    border: 10px solid #fff;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
  .toggle.active::before {
    left: calc(100% - 70px);
    background-color: #fff;
    border-color: #353842;
  }
  

  .react-resizable {
    position: relative;
  }
  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
  }
  .react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }
  .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  .react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }
  .react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }
  .react-resizable-handle-w,
  .react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }
  .react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
  }
  .react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
  }
  .react-resizable-handle-n,
  .react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }
  .react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }
  .react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
  }




  