.topBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 70px 40px 70px;
}

.details{
    display: flex;
    flex-direction: row;
}

.logo{
    font-weight: 300;
    font-size: 3.2rem;
    line-height: 7.6rem;
    text-decoration: none;
    color: black;
}

.logo_accent{
    color: #0088C2;
    font-weight: 600;
}

.pageDetails{
    display: flex;
    flex-direction: row;
    font-size: 4.8rem;
    font-weight: 600;
    padding-left: 14rem;
    line-height: 5.2rem;
}

.pageDetails img{
    width: 8rem;
    height: 8rem;
    border-radius: 8rem;
    margin-right: 1.5rem;
    transform: translate(0,0.7 rem);
}

.subDetails{
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: 400;
    color: #9A9A9A;
    line-height: 2.7rem;
}

.sideButtons{
    display: flex;
    flex-direction: row;
}

.sideButton{
    border: 1px solid #CFCFCF;
    background-color: white;
    border-radius: 1.5rem;
    height: 5.9rem;
    width: 5.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem 0 0.5rem;
    cursor: pointer;

}

.sideButton:hover{
    background-color: #f4f4f4;
}

.hamBurger{
    display: none;
    margin-right: 0;
}

.icon{
    height: 3.2rem;
}

.backButton{
    border-radius: 30rem;
    width: 6rem;
    height: 6rem;
    border: none;
    background-color: white;
    color: #0088C2;
    font-size: 4rem;
    margin-right: 1rem;
    transform: translate(0, 0);
}

.backButton:hover{
    /* transition: 0.2s; */
    outline: 1px solid #0088C2;
    /* background-color: rgb(198, 238, 255); */
}

.backButton img{
    height: 2.5rem;
    transform: translate(-1.9rem,-0.3rem);
}

.notifCont{
    position: relative;
    display: flex;
    flex-direction: column;
}

.notifCont .notificationBar{
    
    z-index: 2;
    position: absolute;
    top: 7rem;
    border-radius: 1rem;
    right: 0rem;
    width: 40rem;
    height: 40rem;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.5s;
    font-size: 1.6rem;
    overflow: scroll;
}

.notifCont .notification{
    padding: 3rem;
    height: 3.5rem;
    border-bottom: 1px solid rgb(203, 203, 203);
    color: #626262;
    cursor: pointer;
    transition: 0.2s;
}

.notifCont .notification:hover{
    background-color: rgb(243, 243, 243);
}

.notification .sub{
    color: #b4b4b4;
    font-size: 1.3rem;
}

.notifAdditional{
    display: flex;
    justify-content: space-between;
    padding: 2rem 2.5rem 2rem 2.5rem;
}

.notifAdditional a{
    color: #959595;
    /* text-decoration: none;
    background-color: #ffffff;
    border: 1px solid #959595;
    border-radius: 0.8rem; */
    padding: 0.5rem;
}

@media screen and (max-width: 767px) {

    .pageDetails img{
        display: none;
    }

    .topBar{
        padding: 20px 35px 20px 35px;

    }


    .details{
        flex-direction: column;
    }

    .hamBurger{
        display: flex;
    }

    .logo{
        font-size: 2.5rem;
    }

    .pageDetails{
        padding-left: 0;
        padding-top: 2rem;
    }

    .sideButton{
        border: none;
        padding: 0;
        width: 4rem;
    }

    .sideButton:hover{
        background-color: white;
    }

    .backButton img{
        display: block;
    }

    .notifCont .notificationBar{
        top: 7rem;
        right: -4.5rem;
        width: 40rem;
        height: 40rem;
    }
}


@media screen and (min-width: 768px) and (max-width:1023px) {
    .topBar{
        padding: 40px 40px 40px 40px;
    }

    .pageDetails{
        padding-left: 100px;
    }

    
}