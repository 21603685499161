.head{
    font-size: 2rem;
    margin-bottom: 3rem;
}

.mainn{
    margin-left: 5rem;
}

.mainn div{
    background-color: #F0F8FB;
    border: 1px solid #0088C2;
    height: 5rem;
    width: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.4rem;
    font-size: 1.5rem;
    color: #0088C2;
    margin: 1rem 0 1rem 0;
}

.mainn div:hover{
    background-color: #c6efff;
    cursor: pointer;
}