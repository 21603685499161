
/* nav */

.navBar{
    display: flex;
    height: 80vh;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    
}

.liActive{
    display: flex;
    opacity: 100;
    transition: all 2s;
}

.sideButton{
    border: none;
    padding: 0;
    width: 4rem;
    background-color: white;
}


.active{
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    border: 1px solid #0088C2;
    background-color: #F0F8FB;
}

.active .navItem{
    color: #0088C2;
}

.navItem{
    text-decoration: none;
    font-size: 2rem;
    font-weight: 500;
    color: #868686;
}


.navItem .icon{
    height: 2rem;
    padding: 0 2rem 0 2rem;
}

.menuText{
    display: none;
}

.menuActive{
    display: inline;
    position: absolute;
    left: 5rem;
    top: 4rem;
    font-weight: 300;
    font-size: 3rem;
}

.closeNav{
    display: none;
    position: fixed;
    top: 0;
    right: 0;
}

.closeNavActive{
    display: inline;
    position: absolute;
    top: 3rem;
    right: 3rem;
    transition: 0.4s cubic-bezier(.17,.67,.83,.67);
    transform: scale(0.7);
}

.dropIcon{
    margin-top: 0.2rem;
    padding-right: 1.5rem;
}

.account{
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    margin: 3rem 3rem 3rem 0;
    align-items: center;
}

.act{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accountDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.accountRole{
    font-size: 1.1rem;
}

.actPic{
    height: 5.2rem;
    width: 5.2rem;
    border-radius: 5.2rem;
    padding: 1rem;
}

.accountButtons{
    display: flex;
    flex-direction: row;
}

.accountButtons button{
    width: 9.9rem;
    height: 3.5rem;
    border-radius: 0.6rem;
    background-color: white;
    border: 1px solid #CFCFCF;
    color: #909090;
    font-size: 1.3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0.3rem;
    cursor: pointer;
}

.accountButtons button:hover{
    background-color: rgb(246, 246, 246);
}

.accountButtons button img{
    height: 1.7rem;
}

.logo_accent{
    color: #0088C2;
    font-weight: 600;
}

@media screen and (max-width: 767px) {
    .account{
        position: absolute;
        top: 8rem;
        align-items: center;
        text-align: center;
        font-size: 2rem;
        margin: 3rem;
    }

    .accountButtons{
        padding: 1.5rem;
    }

    .act{
        flex-direction: column;
        align-items: center;
    }

    .accountRole{
        font-size: 1.5rem;
    }

    .actPic{
        height: 10rem;
        width: 10rem;
        border-radius: 10rem;
    }

    

    

    .navBar{
        position: absolute;
        background-color: rgb(255, 255, 255);
        width: 100vw;
        height: 90vh;
        display: flex;
        flex-direction: column;
        left: -100vw;
        overflow-y: auto;
        overflow-x: hidden;
        align-items: center;
    }

    .navActive{
        transition: 0.4s cubic-bezier(.19,.47,.5,.98);
        left: 0;
    }

    .active{
        color: #0088C2;
        border: none;
        background-color: white;
    }

    

    

    
}