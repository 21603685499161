.mainTable{
    margin: 0rem 0 0 4rem;
    font-size: 1.6rem;
}



.boldDetail{
    font-weight: 600;
    font-size: 1.8rem;
}

.lightDetail{
    color: rgb(119, 119, 119);
}



.bDetail{
    font-size: 1.3rem;
    color: #B1B1B1;
}



.newBtn{
    background-color: #ffffff;
    border: 1px solid #CFCFCF;
    color: #858585;
    height: 5.5rem;
    width: 20rem;
    border-radius: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 2rem 5rem 2rem 5rem;
    font-size: 1.6rem;
    cursor: pointer;
}

.newBtn:hover{
    background-color: #f7f7f7;
}

.rowTitle{
    background-color: #0088C2;
    color: white;
    padding: 0.25rem 1rem 0.25rem 1rem;
    font-weight: 300;
    font-size: 1.3rem;
    column-span: 2;
    border-radius: 16px;
}
.rowTitleP{
    background-color: #0088C2;
    color: white;
    padding: 0.25rem 1rem 0.25rem 1rem;
    font-weight: 300;
    font-size: 1.3rem;
    column-span: 2;
    border-radius: 16px;
}

.breakTd{
    background-color: white;
}

.hasTitle img{
    height: 0.7rem;
}

.hasTitle td,tr,th{
    margin-left: 3rem;
}

td .statIcon{
  height: 1.2rem;
}

.hover:hover{
  transition: 0.3s;
  transform: scale(2);
}




.m{
    display: none;
}

.t{
    display: none;
}

@media screen and (max-width: 767px) {

    .tableTop label{
        font-size: 1.7rem;
    }

    

    .mainTable{
        width: 85vw;
        overflow: auto;
    }

    .m{
        display: table-cell;
    }

    .d{
        display: none;
    }

    

}

:root {
    --color-bg: #eaeaea;
    --color-loader: #0088C2;
    --loader-size: 100px;
    --loader-speed: 500ms;
  }
  
  .loader {
    margin: 2rem 0 0 0rem;
    transform: scale(0.6);
    --loader-height: calc(var(--loader-size) / 7);
    display: flex;
    
    position: relative;
  }
  .loader::before {
    content: '';
    position: absolute;
  }
  .loader::before,
  .loader > span {
    display: block;
    width: var(--loader-height);
    height: var(--loader-height);
    border-radius: 50%;
    background: var(--color-loader);
    
    animation-name: to-right;
    animation-duration: var(--loader-speed);
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .loader > span:nth-child(even) {
    visibility: hidden;
  }
  .loader > span:first-child {
    animation-name: scale-up;
  }
  .loader > span:last-child {
    animation-name: scale-up;
    animation-direction: reverse;
  }
  
  @keyframes to-right {
    to {
      transform: translateX(200%);
    }
  }
  @keyframes scale-up {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }


  @media print
{    
  .breakTd{
    outline: 1rem;
    border-radius: 0;
  }

  .rowTitle{
    background-color: white;
    border: none;
    outline: none;
}

}