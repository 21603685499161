.QRcont{
    width: 100vw;
    /* display: flex; */
}

.QRcont p{
    font-size: 4.5rem;
    text-align: center;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eefaff;
    outline: 1px solid #0088C2;
    color: #0088C2;
    border-radius: 1rem;
    margin: 0 3rem 0 3rem;
}

.QRcont img{
    height: 3.5rem;
    margin: 1rem;
    margin-right: 2rem;
}

.QRcont video{
    transform: scale(0.8);
    outline: 1px solid #0088C2;
    background-color: #f1fbff;
    border-radius: 1rem;
    
}