/* tableTop */
.tableTop{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tableTopItem{
    display: flex;
    flex-direction: column;
    margin: 0 2rem 0 0rem;
    flex-wrap: wrap;
}

.tableTop input{
    width: 19.5rem;
    font-size: 1.6rem;
    height: 4.8rem;
}

.tableTop select{
    height: 5.2rem;
    width: 15.7rem;
}

.tableTop label{
    font-size: 1.5rem;
}

.genRep{
    background-color: #FBF5F0;
    border: 1px solid #DA6900;
    color: #DA6900;
    height: 4.8rem;
    width: 20rem;
    border-radius: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 2rem 5rem 2rem 5rem;
}

.NewBtn{
    background-color: #F0F8FB;
    border: 1px solid #0088C2;
    color: #0088C2;
    height: 4.8rem;
    width: 20rem;
    border-radius: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 2rem 5rem 2rem 5rem;
}

.NewBtn:hover{
    background-color: #d4f4ff;
    cursor: pointer;
}

.EditRoles{
    background-color: hsl(0, 100%, 95%);
    border: 1px solid #c20000;
    color: #c20000;
    height: 4.8rem;
    width: 20rem;
    border-radius: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 1rem;
}

.EditRoles:hover{
    background-color: hsl(0, 100%, 85%);
    cursor: pointer;
}

.AddRoles{
    background-color: #ffffff;
    border: 1px solid #dddddd;
    color: #a4a4a4;
    height: 4.8rem;
    width: 20rem;
    border-radius: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 1rem;
}

.AddRoles:hover{
    background-color: #eeeeee;
    cursor: pointer;
}

.Role{
    background-color: #ffeded;
    border: none;
    color: #c20000;
    height: 4.8rem;
    width: 20rem;
    border-radius: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0.5rem 0;
}

.Role:hover{
    background-color: #ffd1d1;
    cursor: pointer;
}

.mainTable{
    margin: 0rem 0 0 4rem;
    font-size: 1.6rem;
}



.boldDetail{
    font-weight: 600;
    font-size: 1.8rem;
}

.lightDetail{
    color: rgb(119, 119, 119);
}



.bDetail{
    font-size: 1.3rem;
    color: #B1B1B1;
}



.newBtn{
    background-color: #ffffff;
    border: 1px solid #CFCFCF;
    color: #858585;
    height: 5.5rem;
    width: 20rem;
    border-radius: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 2rem 5rem 2rem 1rem;
    font-size: 1.6rem;
    cursor: pointer;
}

.newBtn:hover{
    background-color: #f7f7f7;
}

.buttonRow{
    margin-left: 5rem;
    display: flex;
    margin-top: 1.5rem;
    flex-direction: row;
}

.buttonRow button{
    margin: 0;
    margin-right: 1rem;
}

.rowTitle{
    background-color: #0088C2;
    color: white;
    padding: 0.25rem 1rem 0.25rem 1rem;
    font-weight: 300;
    font-size: 1.3rem;
    column-span: 2;
    border-radius: 16px;
}
.rowTitleP{
    background-color: #0088C2;
    color: white;
    padding: 0.25rem 1rem 0.25rem 1rem;
    font-weight: 300;
    font-size: 1.3rem;
    column-span: 2;
    border-radius: 16px;
}

.breakTd{
    background-color: white;
}

.hasTitle img{
    height: 0.7rem;
}

.hasTitle td,tr,th{
    margin-left: 3rem;
}

.m{
    display: none;
}

.t{
    display: none;
}

@media screen and (max-width: 767px) {

    .tableTop label{
        font-size: 1.7rem;
    }

    

    .mainTable{
        width: 85vw;
        overflow: auto;
    }

    .m{
        display: table-cell;
    }

    .d{
        display: none;
    }

}


/* dropdown */
.dropdownCheckList {
    display: inline-block;
    width: 25rem;
  }
  
  .dropdownCheckList .anchor {
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 1rem 0 1rem 2rem;
    border: 1px solid #e9e9e9;
    border-radius: 1.3rem;
    width: 23rem;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    height: 3rem;
  }
  
  
  .dropdownCheckList .anchor:active:after {
    right: 8px;
    top: 21%;
  }
  
  .dropdownCheckList ul.items {
    padding: 0;
    display: none;
    margin: 0;
    border: none;
    background-color: #f8f8f8;
    border-top: none;
  }
  
  .dropdownCheckList ul.items li {
    list-style: none;

  }
  
  .dropdownCheckList.visible .anchor {
    color: #0094ff;
  }
  
  .dropdownCheckList.visible .items {
    display: block;
    position: absolute;
    width: 25rem;
  }

  .items input{
    border: 1px solid #E9E9E9;
    border-radius: 1.3rem;
    height: 4.5rem;
    width: 3rem;
    padding-left: 0;
    padding-right: 0;
    margin-right: 1rem;
    margin-bottom: 0rem;
    appearance: auto;
    outline-color: #0088C2;
    background-color: white;
    
  }

  .items li:hover{
    border: none;
    background-color: #f0f0f0;
  }

  .items li{
    height: 5.8rem;
    width: auto;
    display: flex;
    justify-content: left;
    align-items: center;
    border-radius: 1.4rem;
    margin: 0;
    align-items: center;
    cursor: pointer;
    padding-left: 2rem;
    font-weight: 300;
  }

  .items{
    height: 35rem;
    overflow: auto;
  }

  .subCheck{
    margin-left: 4rem;
  }


@media screen and (max-width: 767px) {
    .dropdownCheckList .anchor {
        width: 79vw;
    }

    .dropdownCheckList{
        width: 83vw;
        margin-bottom: 1rem;
    }

    .dropdownCheckList.visible .items {
        width: 83vw;
    }


    
}
