.mainn{
    display: block;
    
}

.smainn{
    margin-left: 5rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.8rem;
}

.boxActivity{
    margin-left: 5rem;
    height: 26rem;
    border: 1px solid #838383;
    background-color: #F5F5F5;
    padding: 3rem 3.7rem 3rem 3.7rem;
    font-size: 2rem;
    font-weight: 300;
    color: #616161;
    border-radius: 0.9rem;
    display: block;
    position: relative;
    
}

.chartWrapper{
    width: 26rem;
}

.boxStat{
    margin-right: 2rem;
    height: 26rem;
    width: 28.5vw;
    padding: 3rem 3.7rem 3rem 3.7rem;
    border: 1px solid #0088C2;
    background-color: #F0F8FB;
    padding: 0.6rem;
    font-size: 2rem;
    font-weight: 300;
    color: #616161;
    border-radius: 0.9rem;
    display: block;
    position: relative;
    
    
}

.statP{
    margin-right: 2rem;
    height: 35rem;
    width: 28.5vw;
    /* padding: 3rem 3.7rem 3rem 3.7rem; */
    border: 1px solid #d3d3d3;
    background-color: #ffffff;
    padding: 0.6rem;
    font-size: 2rem;
    font-weight: 300;
    color: #616161;
    border-radius: 0.9rem;
    display: flex;
    justify-content: center;
    position: relative;
    
}

.boxStat .titles{
    margin-left: 3rem;
    margin-top: 2.2rem;
}

.rev{
    color: #0088C2;
    font-size: 3.6rem;
    font-weight: 600;
}

.bar{
    bottom: 1rem;
    left: 0rem;
    position: absolute;
    transform: scale(0.8);
}

.boxStat button{
    width: 12rem;
    height: 3.1rem;
    font-size: 1.3rem;
    border-radius: 0.9rem;
    color: #0088C2;
    border: 1px solid #0088C2;
    background-color: #F0F8FB;
}

.boxStat button:hover{
    background-color: #c9f1ff;
}

.boxNotif{
    height: 26rem;
    width: 28.5vw;
    border: 1px solid #075300;
    background-color: #F6FFE3;
    padding: 0.6rem;
    font-size: 2rem;
    font-weight: 300;
    color: #616161;
    border-radius: 0.9rem;
    display: block;
    position: relative;
    
}

.boxNotif .titles2{
    margin-left: 3rem;
    margin-top: 2.2rem;
}

.boxNotif span{
    font-size: 8rem;
    font-weight: 600;
    /* line-height: 0; */
}

.boxNotif .subNot{
    margin-top: -1rem;
    line-height: 0;
    font-size: 1.5rem;
}

.boxTitle{
    margin-bottom: 2rem;
}

.boxNotif button{
    width: 12rem;
    height: 3.1rem;
    font-size: 1.3rem;
    border-radius: 0.9rem;
    color: #075300;
    border: 1px solid #075300;
    background-color: #F6FFE3;
    transform: translate(0,0.8rem);
}

.boxNotif button:hover{
    background-color: #e8ffb8;
}

.log{
    font-size: 1.7rem;
    color: #838383;
    margin-bottom: 0.7rem;
}

.buttonHolder{
    position: absolute;
    bottom: 3rem;
}

.buttonHolderStat{
    position: absolute;
    right: 2rem;
    bottom: 2rem;
}

.boxActivity button{
    width: 10rem;
    height: 3.1rem;
    font-size: 1.3rem;
    border-radius: 0.9rem;
    color: #616161;
    border: 1px solid #616161;
}

.boxActivity button:hover{
    background-color: #e2e2e2;
}

.logsContainer{
    margin: 0 0 5rem 0;
}

.numbersContainer{
    display: flex;
    flex-direction: row;
    margin-left: 5rem;
    font-size: 1.5rem;
    /* justify-content: space-between; */
    width: 60vw;
}

.numbersContainer p{
    margin: 1.5rem;
}

.nR{
    margin-right: 2rem;
    width: 29.5vw;
    height: 12.5rem;
    border-radius: 0.9rem;
    background-color: #FBF0F0;
    border: 1px solid #990000;
}

.nR:hover{
    background-color: #ffdada;

}

.nR p{
    color: #990000;
}

.nR h1{
    margin-left: 2rem;
    font-size: 4rem;
    color: #990000;
}

.tR{
    /* margin-right: 1rem; */
    width: 29.5vw;
    height: 12.5rem;
    border-radius: 0.9rem;
    background-color: #F6FFE3;
    border: 1px solid #075300;
}

.tR:hover{
    background-color: #e7ffb3;

}

.tR h1{
    margin-left: 2rem;
    font-size: 4rem;
    color: #075300;
}

.tR p{
    color: #075300;
}

.totals{
    margin-right: 1rem;
    width: 14vw;
    height: 10.5rem;
    border-radius: 0.9rem;
    background-color: #F0F8FB;
    border: 1px solid #0088C2;
}

.totals h1{
    margin-left: 2rem;
    font-size: 2.5rem;
    color: #0088C2;
}

.totals p{
    color: #0088C2;
}

@media screen and (max-width: 767px) {
    .boxActivity{
        border: 1px solid #c3c3c3;
        padding: 3rem 3rem 3rem 3rem;
        height: auto;
        width: 68vw;
    }

    .boxStat{
        width: 77vw;
        margin-bottom: 1.5rem;
        border: 1px solid #0088C2;
    }

    .boxNotif{
        width: 77vw;
    }

    .log{
        font-size: 1.5rem;
    }
    .smainn{

        margin-left: 5rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 1.4rem;
    }
}

@media screen and (min-width: 768px) and (max-width:1023px) {

    
}