.mainCont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.mainCont p{
    font-size: 4rem;
    margin: 2rem;
}

.mainCont input{
    height: 6.2rem;
    width: 34.9rem;
    font-size: 1.6rem;
    background-color: #F4F4F4;
    border: none;
}

.mainCont .button{
    height: 5.8rem;
    width: 21.8rem;
    border-radius: 1.4rem;
    border: 1px solid #0088C2;
    background-color: #F0F8FB;
    color: #0088C2;
    margin: 2rem;
}

.mainCont .button:hover{
    
    background-color: #d3f3ff;
}

.mainCont .error{
    font-size: 2rem;
    color: red;
    text-align: center;
}

.loaderDiv {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgb(255, 255, 255);
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader {
	position: relative;
	width: 10vw;
	height: 5vw;
	padding: 1.5vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader span {
	position: absolute;
	height: 0.8vw;
	width: 0.8vw;
	border-radius: 50%;
	background-color: #0088C2;
}

.loader span:nth-child(1) {
	animation: loading-dotsA 0.5s infinite linear;
}

.loader span:nth-child(2) {
	animation: loading-dotsB 0.5s infinite linear;
}

@keyframes loading-dotsA {
	0% {
		transform: none;
	}
	25% {
		transform: translateX(2vw);
	}
	50% {
		transform: none;
	}
	75% {
		transform: translateY(2vw);
	}
	100% {
		transform: none;
	}
}

@keyframes loading-dotsB {
	0% {
		transform: none;
	}
	25% {
		transform: translateX(-2vw);
	}
	50% {
		transform: none;
	}
	75% {
		transform: translateY(-2vw);
	}
	100% {
		transform: none;
	}
}

@media screen and (max-width: 767px) {
    .loader{
        transform: scale(3);
    }

    .mainCont .error{
        padding: 0 3rem 0 3rem;
    }

}