/* entries */
.formGroup {
    display: flex;
    flex-direction: row;
}

.buttonConttt{
    display: flex;
    flex-direction: row;
}


.names{
    color: #9A9A9A;
    
}

.values{
    color: #5C5C5C;
}

.pastTenant{
    background-color: #edf9ff;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
    border-radius: 0.8rem;
    border: 1px solid #0088C2;
    color: #0088C2;
    cursor: pointer;
    margin-bottom: 1rem;
}

.pastTenant:hover{
    background-color: #dcf3ff;

}


.oldBar{
    background-color: #e9e9e9;
    width: 90rem;
    font-size: 2rem;
    padding: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.oldData{
    background-color: #e9e9e9;
    width: 87rem;
    padding: 3rem;
    border-radius: 2rem;
    color: black;
}

.submit{
    transition: 0.3s;
    background-color: #3FA700;
    color: white;
    width: 14.7rem;
    height: 4.8rem;
    border-radius: 0.7rem;
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.qsubmit{
    transition: 0.3s;
    background-color: #3FA700;
    color: white;
    width: 14.7rem;
    height: 4.8rem;
    border-radius: 0.7rem;
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    left: 0rem;
    position: absolute;
    z-index: 0;
}

.fun{
    transition: 0.3s;
    position: absolute;
    left: 30rem;
}

.approve{
    background-color: #3FA700;
    color: white;
    width: 32rem;
    height: 4.8rem;
    border-radius: 0.7rem;
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.submit:hover{
    background-color: #46b700;
}

.red{
    background-color: #A70000;
}

.red:hover{
    background-color: #be0000;
}




.upFileButton{
    width: 14.7rem;
    height: 4.8rem;
    background-color: white;
    outline: solid 1px black;
    cursor: pointer;
    border-radius: 7px;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0;
    margin-bottom: 3rem;
    color: black;
    
}

.formDetail{
    display: flex;
    flex-direction: row;
}

.detail{
    padding-right: 15rem;
}

.detailHeader{
    font-size: 2rem;
    color: #0088C2;
    margin-bottom: 1.5rem;
}

.detailHeader img{
    height: 2.5rem;
    margin: 0 1rem 0 0;
}

.detail .upFileButton{
    width: 20rem;
    margin: 1rem 0 1rem 0;
}

.subDetail{
    display: flex;
    flex-direction: column;
}

.subDetail label{
    width: 31rem;
}

.black{
    color: black;
}

.upFileButton img{
    padding: 1.8rem;
}

.upFileButton:hover{
    background-color: rgb(241, 241, 241);
}

.paid{
    color: rgb(0, 203, 7);
    font-weight: 500;
    font-size: 2.5rem;
}

.excess{
    color: rgb(180, 0, 0);
    font-weight: 500;
    font-size: 2.5rem;
}

.dp{
    height: 20rem;
    width: 20rem;
    border-radius: 0.7rem;
    margin-right: 2rem;
}

.dpChange{
    width: 20rem;
    height: 4.8rem;
    margin-top: 2rem;
    background-color: white;
    outline: solid 1px black;
    cursor: pointer;
    border-radius: 7px;
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 3rem;
    color: black;
}

.dpChange img{
    padding: 1.8rem;
}

.mainProfileHolder{
    display: flex;
    flex-direction: row;
}

.subProfileHolder{
    display: flex;
    flex-direction: row;
}

.masterSubDetail{
    display: flex;
    flex-direction: row;
}

.masterSubDetail .subDetail{
    width: 27rem;
}

.subDetailCheck{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mainInpCheck{
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
    appearance: auto;
    width: 3rem;
    transform: translate(0, 0.7rem);
    accent-color: #0088C2;
    margin-left: 1.5rem;
    height: 3.5rem;
    opacity: 0.4;
}

.mainInpCheck:checked{
    opacity: 1;
}

.inpCheck{
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
    appearance: auto;
    width: 5rem;
    accent-color: #0088C2;
    margin-right: 1.5rem;
    height: 3.5rem;
    opacity: 0.4;
}

.inpCheck:checked{
    opacity: 1;
}

.detailHeaderC{
    font-size: 1.6rem;
    color: #444444;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.detailHeaderCC{
    font-size: 1.6rem;
    color: #9A9A9A;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.detailHeaderC img{
    margin-right: 1rem;
    height: 2rem;
}

.prevEntCont{
    margin-left: 5rem;
    margin-bottom: 5rem;
    padding: 1rem;
    border-radius: 1rem;
    background-color: #fbfafa;
    font-size: 1.8rem;
    color: #979797;
}

.prevEntCont p{
    padding: 1rem;
}

.prevEntD{
    font-size: 1.6rem;
    font-weight: 400;
    color: #000000;
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid #e2e2e2;
    cursor: pointer;
}

.prevEntD:hover{
    background-color: #dddddd;
}


@media screen and (max-width: 767px) {
    .prevEntCont{
        margin-top: 2rem;
        padding: 0;
        background-color: white;
        width: 78vw;
    }

    .prevEntCont p{
        text-align: center;
        margin-left: 0;
    }

    .prevEntD{
        margin-top: 1rem;
        padding: 0;
        margin-left: 0;
    }

    .dp{
        width: 40vw;
        height: 40vw;
        border-radius: 2rem;
        margin-right: 2rem;
    }

    .mainProfileHolder{
        margin: auto;
        flex-direction: column;
    }

    .submit{
        font-size: 2rem;
        width: calc(14.7rem*1.2);
        height: calc(4.8rem*1.2);
    }

    .approve{
        font-size: 1.6rem;
        width: calc(14.7rem*1.2*1.8);
        height: calc(4.8rem*1.1);
    }

    .formDetail{
        flex-direction: column;
    }

    .detail{
        padding: 0;
    }

    .masterSubDetail{
        display: flex;
        flex-direction: column;
    }
    
}

@media screen and (min-width: 768px) and (max-width:1023px) {
    .formDetail{
        flex-direction: column;
    }

    

}

.switch {
    margin-bottom: 2rem;
    --inactive-bg: #cfd8dc;
    --active-bg: #0088C2;
    --size: 2rem;
    appearance: none;
    width: calc(var(--size) * 2.2);
    height: var(--size);
    display: inline-block;
    border-radius: calc(var(--size) / 2);
    cursor: pointer;
    background-color: var(--inactive-bg);
    background-image: radial-gradient(
        circle calc(var(--size) / 2.1),
        #fff 100%,
        #0000 0
      ),
      radial-gradient(circle calc(var(--size) / 1.5), #0003 0%, #0000 100%);
    background-repeat: no-repeat;
    background-position: calc(var(--size) / -1.75) 0;
    transition: background 0.2s ease-out;
  }
  .switch:checked {
    background-color: var(--active-bg);
    background-position: calc(var(--size) / 1.75) 0;
  }
  

